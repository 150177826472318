<template>
  <div class="repairer-marketplace-page">
    <div class="page-header">
      <h4>Marketplace</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="{ name: 'RepairerDashboard'}">Home</router-link>
        </li>
        <li
            class="breadcrumb-item active"
            style="opacity: 1;"
            @click="isCollapsed = !isCollapsed"
            :class="{
              'active-menu-item': isMobileOnly && isCollapsed
            }">
            <router-link :to="{ name: 'RepairerMarketplace'}">View Marketplace</router-link>
        </li>
      </ol>
    </div>

    <div class="d-flex mt-4">
      <div v-if="(!isCollapsed && isMobileOnly) || !isMobileOnly" class="repairer-marketplace-body">
        <div class="repairer-marketplace-menu-block">
          <div class="tab-marketplace-category title-orders-block btn-orders-block"
               :class="{'active-report': selectedStore === 'Stores'}">
            <div class="ml-3">Stores</div>
            <div class="tab-marketplace-category-line"></div>
          </div>
          <div class="title-orders-block btn-orders-block"
               :class="{'active-report': selectedStore === 'MSAutomotivePaint'}">Automotive
          </div>
          <div class="btn-orders-block" style="cursor: default">Paint
          </div>
          <div class="btn-orders-block"
               :class="{'active-report': selectedStore === 'MSAutomotivePaint'}">Consumables
          </div>

          <div v-if="companyInfo && companyInfo.isCCNSW" class="btn-orders-block" style="margin-left: 30px"
               :class="{'active-report': selectedStore === 'MSAutomotivePaint'}"
               @click="selectStore('MSAutomotivePaint')">M&S Automotive Paint
          </div>

          <div class="title-orders-block btn-orders-block"
               :class="{'active-report': selectedStore === 'Backstaff'}">Services
          </div>
<!--          <div class="btn-orders-block" @click="selectCategory2('Recruitment')"
               :class="{'active-report': isStoresCategory2 === 'Recruitment'}">Recruitment
          </div>-->
          <div class="btn-orders-block"
               :class="{'active-report': selectedStore === 'Backstaff'}">Bookkeeping
          </div>
          <div class="btn-orders-block" @click="selectStore('Backstaff')"
               :class="{'active-report': selectedStore === 'Backstaff'}" style="margin-left: 30px">Backstaff
          </div>

          <div class="title-orders-block btn-orders-block"
               :class="{'active-report': selectedStore === 'NTech'}">Office Tech
          </div>
          <div class="btn-orders-block" @click="selectStore('NTech')"
               :class="{'active-report': selectedStore === 'NTech'}">N-TECH
          </div>
<!--          <div class="btn-orders-block"-->
<!--               >Office PC's-->
<!--          </div>-->
<!--          <div class="btn-orders-block"-->
<!--              >Software-->
<!--          </div>-->
<!--          <div class="btn-orders-block"-->
<!--               >Printers-->
<!--          </div>-->
          <div class="btn-orders-block"
              >Telephony
          </div>


<!--          <tabs v-if="false" ref="repairer-orders-table" cache-lifetime="0">-->
<!--            <tab class-name="tab-content-table" name="My Orders" id="myOrders" ref="myOrders">-->
<!--              <div class="btn-orders-block" @click="selectCategory('Orders')"-->
<!--                   :class="{'active-report': isStoresCategory === 'Orders'}">Orders-->
<!--              </div>-->
<!--              <div class="btn-orders-block" @click="selectCategory('Refunds')"-->
<!--                   :class="{'active-report': isStoresCategory === 'Refunds'}">Refunds-->
<!--              </div>-->

<!--            </tab>-->
<!--          </tabs>-->
        </div>
      </div>
<!--      <div v-if="!selectedStore && !isStoresCategory2 && isStoresCategory !== 'Automotive' && false" class="m-3 w-100">-->
<!--        <div v-if="isStoresCategory === 'Stores'">-->
<!--          <div class="d-flex justify-content-between align-items-center">-->
<!--            <h4 class="mb-0">Featured {{ isStoresCategory }}</h4>-->
<!--            <div class="tabs-nav-right-search">-->
<!--              <b-form-input-->
<!--                  v-model="filter"-->
<!--                  type="text"-->
<!--                  class="search-input"-->
<!--                  placeholder="Search for Suppliers"-->
<!--                  debounce="500">-->
<!--              </b-form-input>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="featured-stores-wrapper">-->
<!--            <div v-for="store in stores.slice(0, 4)" :key="store.id" class="featured-store-block"-->
<!--                 @click="selectStore(store)">-->
<!--              <h4 class="featured-store-title" :style="{'color': store.color, 'background-color': store.background}">-->
<!--                {{ store.name }}</h4>-->
<!--              <p class="featured-store-text">{{ store.description }}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div-->
<!--            v-if="isStoresCategory === 'Services' || isStoresCategory === 'Office Tech'">-->
<!--          <div class="d-flex justify-content-between align-items-center">-->
<!--            <h4 class="mb-0">{{ isStoresCategory }}</h4>-->
<!--            <div class="tabs-nav-right-search">-->
<!--              <b-form-input-->
<!--                  v-model="filter"-->
<!--                  type="text"-->
<!--                  class="search-input"-->
<!--                  placeholder="Search for Suppliers"-->
<!--                  debounce="500">-->
<!--              </b-form-input>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="featured-stores-wrapper">-->
<!--            <div v-for="store in filteredStores" :key="store.id" class="featured-store-block">-->
<!--              <div @click="selectStore(store)" class="h-100">-->
<!--                <h4 class="featured-store-title" :style="{'color': store.color, 'background-color': store.background}">-->
<!--                  {{ store.name }}</h4>-->
<!--                <p class="featured-store-text">{{ store.description }}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div v-if="isStoresCategory !== 'Automotive'"  class="see-more-btn d-flex justify-content-end m-4">-->
<!--          See More >-->
<!--        </div>-->

<!--        <div v-if="isStoresCategory !== 'Automotive'"  class="d-flex">-->
<!--          <div class="information-block">-->
<!--            <h4>PARTSSEARCH Marketplace</h4>-->
<!--            <br>-->
<!--            <p>Buy products and services from your suppliers, online and all-in-one place.</p>-->
<!--          </div>-->
<!--          <div class="information-block">-->
<!--            <h4>Not SEEING your supplier in here</h4>-->
<!--            <br>-->
<!--            <p>Earn <b>$250</b> cash when you invite your supplier & they sign up. <br><br>Plus, receive additional-->
<!--              discounts on purchases for 6 months.</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div v-if="isStoresCategory !== 'Automotive'" class="see-more-btn d-flex justify-content-end m-4">-->
<!--          See More >-->
<!--        </div>-->

<!--        <div>-->
<!--          <h4 v-if="isStoresCategory === 'Stores'">Stores servicing your region</h4>-->
<!--          <h4 v-else-if="isStoresCategory !== 'Automotive'">Suppliers in your Region</h4>-->
<!--          <div v-if="isStoresCategory !== 'Automotive'" class="featured-stores-wrapper">-->
<!--            <div v-for="store in stores.slice(0, 4)" :key="store.id" class="featured-store-block"-->
<!--                 @click="selectStore(store)">-->
<!--              <h4 class="featured-store-title default-store-color"></h4>-->
<!--              <p class="featured-store-text"></p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div
          :class="{'hidden-el': (!isSelectedStore), 'visible-el': isSelectedStore , 'mobile-marketplace-view': isMobileOnly}"
          class="m-3 w-100">
        <store-96072300
            v-if="companyInfo && companyInfo.isCCNSW"
            :class="{
          'hidden-el': selectedStore != 'MSAutomotivePaint',
           'visible-el': selectedStore == 'MSAutomotivePaint'}"
            @selectStore="selectStore"
        ></store-96072300>
        <store-backstaff
            :class="{
              'hidden-el': selectedStore !== 'Backstaff',
               'visible-el': selectedStore == 'Backstaff'}"
            @selectStore="selectStore"
        ></store-backstaff>
        <store-90780259
            :class="{
              'hidden-el': selectedStore !== 'NTech',
               'visible-el': selectedStore == 'NTech'}"
        ></store-90780259>
      </div>
    </div>


  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import numberFormatter from '../../../../src/components/utility/number-formatter'
import Store96072300 from '@/views/repairers/marketplace/store-96072300.vue'
import Store90780259 from '@/views/repairers/marketplace/store-90780259.vue'
import StoreBackstaff from '@/views/repairers/marketplace/store-backstaff'
import { mapGetters } from 'vuex'
import { isMobileOnly } from 'mobile-device-detect'

export default {
  name: 'marketplace',
  data () {
    return {
      isCollapsed: true,
      filter: '',
      stores: [
        {
          id: 1,
          name: 'Store 1',
          description: 'Paint Supplies',
          category: 'Automotive',
          category2: 'Paint',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 2,
          name: 'Store 2',
          description: 'Office PCs',
          category: 'Office Tech',
          category2: 'Office PCs',
          color: '#0d0d0d',
          background: '#7ab1bb'
        },
        {
          id: 3,
          name: 'Store 3',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 4,
          name: 'Store 4',
          description: 'Paint Supplies',
          category: 'Automotive',
          category2: 'Paint',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 5,
          name: 'Store 5',
          description: 'Office PCs',
          category: 'Office Tech',
          category2: 'Office PCs',
          color: '#fff',
          background: '#da2e2e'
        },
        {
          id: 6,
          name: 'Store 6',
          description: 'Software',
          category: 'Office Tech',
          category2: 'Software',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 6,
          name: 'Store 6',
          description: 'Printers',
          category: 'Office Tech',
          category2: 'Printers',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 7,
          name: 'Store 7',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 8,
          name: 'Store 8',
          description: 'Recruitment',
          category: 'Services',
          category2: 'Recruitment',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 9,
          name: 'Store 9',
          description: 'Consumables',
          category: 'Automotive',
          category2: 'Consumables',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 32,
          name: 'Sape',
          description: 'Paint Supplies',
          category: 'Automotive',
          category2: 'Paint',
          color: '#fff',
          background: '#da2e2e',
          fullDescription: `Sydney Automotive Paint & Equipment is partnered with Protection Brands who is Australia's leading vehicle care provider, offering best in class paint & protection products as well as vehicle tinting and sanitisation for the major Aviation companies, as well as the Automotive and Marine markets around the globe.`
        },
        {
          id: 11,
          name: 'Store 11',
          description: 'Office PCs',
          category: 'Products',
          category2: 'Office PCs',
          color: '#0d0d0d',
          background: '#7ab1bb'
        },
        {
          id: 10,
          name: 'Store 12',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 13,
          name: 'Store 13',
          description: 'Recruitment',
          category: 'Services',
          category2: 'Recruitment',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 14,
          name: 'Store 14',
          description: 'Consumables',
          category: 'Automotive',
          category2: 'Consumables',
          color: '#fff',
          background: '#da2e2e'
        },
        {
          id: 15,
          name: 'Store 15',
          description: 'Paint Supplies',
          category: 'Automotive',
          category2: 'Paint',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 16,
          name: 'Store 16',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 17,
          name: 'Store 17',
          description: 'Paint Supplies',
          category: 'Automotive',
          category2: 'Paint',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 18,
          name: 'Store 18',
          description: 'Recruitment',
          category: 'Services',
          category2: 'Recruitment',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 19,
          name: 'Store 19',
          description: 'Paint Supplies',
          category: 'Automotive',
          category2: 'Paint',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 20,
          name: 'Store 20',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#7ab1bb'
        },
        {
          id: 21,
          name: 'Store 21',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 22,
          name: 'Store 22',
          description: 'Software',
          category: 'Office Tech',
          category2: 'Software',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 22,
          name: 'Store 22',
          description: 'Printers',
          category: 'Office Tech',
          category2: 'Printers',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 23,
          name: 'Store 23',
          description: 'Office PCs',
          category: 'Office Tech',
          category2: 'Office PCs',
          color: '#fff',
          background: '#da2e2e'
        },
        {
          id: 24,
          name: 'Store 24',
          description: 'Paint Supplies',
          category: 'Automotive',
          category2: 'Paint',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 25,
          name: 'Store 25',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 26,
          name: 'Store 26',
          description: 'Consumables',
          category: 'Automotive',
          category2: 'Consumables',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 27,
          name: 'Store 27',
          description: 'Office PCs',
          category: 'Office Tech',
          category2: 'Office PCs',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 28,
          name: 'Store 28',
          description: 'Paint Supplies',
          category: 'Automotive',
          category2: 'Paint',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 29,
          name: 'Store 29',
          description: 'Software',
          category: 'Office Tech',
          category2: 'Software',
          color: '#0d0d0d',
          background: '#7ab1bb'
        },
        {
          id: 29,
          name: 'Store 29',
          description: 'Printers',
          category: 'Office Tech',
          category2: 'Printers',
          color: '#0d0d0d',
          background: '#7ab1bb'
        },
        {
          id: 30,
          name: 'Store 30',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 31,
          name: 'Store 31',
          description: 'Paint Supplies',
          category: 'Automotive',
          category2: 'Paint',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 32,
          name: 'Store 32',
          description: 'Recruitment',
          category: 'Services',
          category2: 'Recruitment',
          color: '#fff',
          background: '#da2e2e'
        },
        {
          id: 33,
          name: 'Store 33',
          description: 'Consumables',
          category: 'Automotive',
          category2: 'Consumables',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 34,
          name: 'Store 34',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 35,
          name: 'Store 35',
          description: 'Software',
          category: 'Office Tech',
          category2: 'Software',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 35,
          name: 'Store 35',
          description: 'Printers',
          category: 'Office Tech',
          category2: 'Printers',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 36,
          name: 'Store 36',
          description: 'Office PCs',
          category: 'Office Tech',
          category2: 'Office PCs',
          color: '#fff',
          background: '#0d0d0d'
        },
      ],
      categoryOption: ['Complete', 'Partial'],
      categoryOptionValue: '',
      sortByOption: ['Price Low to Hight', 'Price Hight to Low'],
      sortByOptionValue: 'Price Low to Hight',
      storeTableFields: [
        { label: 'Chkbx', key: 'chkbx', sortable: false, tdClass: '', thClass: 'text-left align-center' },
        { label: 'Image', key: 'image', sortable: false, tdClass: '', thClass: 'text-center align-center' },
        { label: 'Description', key: 'description', sortable: false, tdClass: '', thClass: 'text-left align-center' },
        {
          label: 'Stock',
          key: 'stock',
          sortable: false,
          tdClass: ' text-nowrap font-weight-bold text-left align-center',
          thClass: 'text-left align-center'
        },
        {
          label: 'Price',
          key: 'price',
          sortable: false,
          tdClass: ' font-weight-bold',
          thClass: 'text-left align-center'
        },
        { label: 'Qty', key: 'qty', sortable: false, tdClass: 'qty-block', thClass: 'text-left align-center' },
        {
          label: 'Action',
          key: 'action',
          sortable: false,
          tdClass: ' text-center align-center',
          thClass: 'text-center align-center'
        },
      ],
      items: [
        {
          id: 1,
          name: 'SEM COLOR HORIZON MIXING CLEAR 946mL',
          description: 'SEM COLOR HORIZON MIXING CLEAR 946mL',
          descriptionText: 'Base Colors are fast covering clean colors that provides the maximum color effect for custom paint jobs.',
          number: 'S2064',
          image: '4013074499.jpg',
          stock: 'In Stock',
          price: '95.26',
          qty: 1
        },
        {
          id: 2,
          name: 'Larsen',
          description: 'SEM COLOR HORIZON MIXING CLEAR 3.78L',
          descriptionText: 'Custom Finish Mix Clear is a single component colorless coating used as a carrier for custom finish concentrates and effect pigments.',
          number: 'S2060',
          image: '4013113865.jpg',
          stock: 'In Stock',
          price: '331.34',
          qty: 1
        },
        {
          id: 3,
          name: 'Geneva',
          description: 'SEM TITANIUM BASE 946mL',
          descriptionText: 'Base Colors are fast covering clean colors that provides the maximum color effect for custom paint jobs.',
          number: 'S2204',
          image: '4013145254.jpg',
          stock: 'In Stock',
          price: '156.24',
          qty: 1
        },
      ]
    }
  },
  mounted() {
  },
  methods: {
    selectCategory (option) {
      this.$store.commit('marketplace/marketplaceSetMenu', {
        selectedStore: option,
      })
      this.isCollapsed = true

      let id = 0;
      if (this.isStoresCategory == 'Automotive') {
        if (this.isStoresCategory2 == 'Consumables') {
          id = 174416765;
        } else if (this.isStoresCategory2 == 'Paint') {
          id = 174416764;
        }
      }

      if (this.isStoresCategory == 'Office Tech') {
        if (this.isStoresCategory2 == 'Software') {
          id = 174603522;
        } else if (this.isStoresCategory2 == 'Printers') {
          id = 174603523;
        } else if (this.isStoresCategory2 == 'Telephony') {
          id = 174603525;
        } else if (this.isStoresCategory2 == 'Office PCs') {
          id = 174595792;
        }
      }

      let storeId = this.isStoresCategory == 'Office Tech' ? 'marketplace-90780259' : 'marketplace-96072300'

      if (this.isStoresCategory == 'Automotive') {
        this.$nextTick(() => {
          let iframe = document.getElementById(storeId)

          _.forEach(iframe.contentWindow.document.querySelectorAll('script'), el => {
            if (_.includes(el?.innerHTML,'Ecwid.OnAPILoaded.add(function() {Ecwid.openPage(\'category\',')) {
              el.parentNode.removeChild(el);
            }
          })

          const script = document.createElement('script');
          script.setAttribute('type', 'module');
          script.text = `Ecwid.OnAPILoaded.add(function() {Ecwid.openPage('category', { id: ${id} });})`
          iframe.contentWindow.document.body.appendChild(script);
        })
      }
    },
    selectCategory2 (option) {
      console.log('Selected Category2: ', option)
      return
      let isStoresCategory = null

      if (option === 'All Paints' || option === 'Paint' || option === 'Consumables') {
        isStoresCategory = 'Automotive'
      } else if (option === 'Recruitment' || option === 'Bookkeeping') {
        isStoresCategory = 'Services'
      } else if (option === 'Office PCs' || option === 'Printers' || option == 'N-TECH' || option == 'Telephony' || option == 'Software') {
        isStoresCategory = 'Office Tech'
      }

      this.$store.commit('marketplace/marketplaceSetMenu', {
        isStoresCategory: isStoresCategory,
        isStoresCategory2: option,
        selectedStore: '',
      })

      this.isCollapsed = true

      let id = 0;
      if (this.isStoresCategory == 'Automotive') {
        if (this.isStoresCategory2 == 'Consumables') {
          id = 174416765;
        } else if (this.isStoresCategory2 == 'Paint') {
          id = 174416764;
        }
      }

      if (this.isStoresCategory == 'Office Tech') {
        if (this.isStoresCategory2 == 'Software') {
          id = 174603522;
        } else if (this.isStoresCategory2 == 'Printers') {
          id = 174603523;
        } else if (this.isStoresCategory2 == 'Telephony') {
          id = 174603525;
        } else if (this.isStoresCategory2 == 'Office PCs') {
          id = 174595792;
        }
      }

      let storeId = this.isStoresCategory == 'Office Tech' ? 'marketplace-90780259' : 'marketplace-96072300'

      if (this.isStoresCategory == 'Automotive') {
        this.$nextTick(() => {
          let iframe = document.getElementById(storeId)

          _.forEach(iframe.contentWindow.document.querySelectorAll('script'), el => {
            if (_.includes(el?.innerHTML,'Ecwid.OnAPILoaded.add(function() {Ecwid.openPage(\'category\',')) {
              el.parentNode.removeChild(el);
            }
          })

          const script = document.createElement('script');
          script.setAttribute('type', 'module');
          script.text = `Ecwid.OnAPILoaded.add(function() {Ecwid.openPage('category', { id: ${id} });})`
          iframe.contentWindow.document.body.appendChild(script);
        })
      }
    },
    selectStore (store) {
      // let storeV = store
      // let isStoresCategory = null
      // if (storeV.category === 'All Paints' || storeV.category === 'Paint' || storeV.category === 'Consumables') {
      //   isStoresCategory = 'Automotive'
      //   storeV = 'All Paints'
      // } else if (storeV.category === 'Recruitment' || storeV.category === 'Bookkeeping') {
      //   isStoresCategory = 'Services'
      //   storeV = 'Services'
      // } else if (storeV.category === 'Printers' || storeV.category === 'Telephony' || storeV.category === 'Software'  || storeV.category === 'Office PCs' || storeV.category === 'N-TECH') {
      //   isStoresCategory = 'Office Tech'
      //   storeV.category = 'Office Tech'
      // }

      this.$store.commit('marketplace/marketplaceSetMenu', {
        selectedStore: store,
      })
    }
  },
  computed: {
    ...mapGetters({
      selectedStore: 'marketplace/selectedStore',
      companyInfo: 'currentCompany/getDetails',
    }),
    isSelectedStore () {
      return (this.selectedStore == 'MSAutomotivePaint' || this.selectedStore == 'Backstaff' || this.selectedStore == 'NTech')
    },
    isMobileOnly () {
      return isMobileOnly
    },
    filteredStores () {
      // let filtered = []
      // if (this.isStoresCategory === 'Stores') {
      //   filtered = this.stores
      // } else if (this.isStoresCategory === 'Automotive') {
      //   filtered = this.stores.filter(store => store.category2 === 'Paint' || store.category2 === 'Consumables' || store.category2 === 'All Paints')
      // } else if (this.isStoresCategory === 'Services') {
      //   filtered = this.stores.filter(store => store.category2 === 'Recruitment' || store.category2 === 'Bookkeeping')
      // } else if (this.isStoresCategory === 'Office Tech') {
      //   filtered = this.stores.filter(store => store.category2 === 'Office PCs' || store.category2 === 'Printers' || store.category2 === 'Telephony' || store.category2 === 'Software' || store.category2 === 'N-TECH')
      // } else {
      //   filtered = this.stores.filter(store => store.category2 === this.isStoresCategory)
      // }
      // return filtered.slice(0, 4)
    },
  },
  components: {
    Store96072300,
    Store90780259,
    StoreBackstaff,
    Multiselect,
    numberFormatter,
  },
}
</script>

<style scoped>
.hidden-el {
  visibility: hidden !important;
  height: 1px !important;
  width: 1px !important;
  position: absolute;
  top: -2000px;
}

.visible-el {
  visibility: visible !important;
}

.breadcrumb-item {
  cursor: pointer;
}

.active {
  cursor: default;
}

.repairer-marketplace-menu-block {
  width: 220px;
  border: 1px solid rgba(27, 30, 56, 0.2);
  border-radius: 3px;
  padding: 15px;
}

.featured-stores-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.featured-store-block {
  color: #1c1f39;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 5px;
  width: 100%;
  margin-right: 10px;
  height: 250px;
  cursor: pointer;
}

.featured-store-block:last-child {
  margin-right: 0;
}

.featured-store-title {
  padding: 15px;
  height: 50%;
  text-transform: uppercase;
}

.featured-store-text {
  margin: 15px;
}

.tab-marketplace-category {
  color: #79B1BB;
  display: flex;
  flex-direction: column;
}

.tab-marketplace-category-line {
  margin-top: 10px;
  height: 5px;
  width: 50%;
  border-radius: 5px 5px 0 0;
  background-color: #79B1BB;
}

.selected-store-title {
  background-color: #da2e2e;
  color: #fff;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 5px;
  width: 100%;
  height: 250px;
  padding: 50px;
  font-size: 70px;
  text-transform: uppercase;
}

.selected-store-text {
  border: 1px solid rgba(27, 30, 56, 0.25);
  background-color: #f2f2f2;
  border-radius: 5px;
  width: 100%;
  height: 100px;
  padding: 15px;
  margin-top: 15px;
}

input.form-control.search-input {
  padding-left: 10px !important;
  padding-right: 35px;
  height: 32px;
  background: url('../../../assets/img/search-icon.svg') no-repeat center right;
}

.information-block {
  width: 50%;
  color: #1c1f39;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 5px;
  width: 100%;
  margin-right: 10px;
  cursor: pointer;
  padding: 15px;
  background-color: #f2f2f2;
}

.information-block:last-child {
  margin-right: 0;
}

.see-more-btn {
  font-size: 1rem;
  font-weight: bold;
  color: #79B1BB;
  cursor: pointer;
}

.default-store-color {
  background-color: #f2f2f2;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e3e4e7;
  padding: 15px;
}

.table-header div p {
  margin: 0;
}

.iframe-wrapper {
  width: 100%;
  /*height: calc(100vh - 700px);*/
  height: calc(100vh - 150px);
}

.description-name {
  color: #da2e2e;
}

.description-text {
  margin-top: 10px;
}

.description-number {
  margin-top: 10px;
}

.qty-block {
  max-width: 80px;
}

.image-block,
.image-block img {
  max-width: 120px;
}
</style>

<style>
.repairer-marketplace-body {
  transform: translateX(-150%);
  animation: ani 0.5s forwards;
}
@keyframes ani {
  0% {transform: translateX(-150%);}
  100% {transform: translateY(0);}
}

.V3 .mobile-marketplace-view {
  margin: 0px !important;
}

.mobile-marketplace-view h4,
.mobile-marketplace-view .company-info {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mobile-marketplace-view .company-logo {
  width: 95%;
  margin: auto;
}

.repairer-marketplace-page .nav-tabs-2 .nav-link.active,
.repairer-marketplace-page .nav-tabs-2 .nav-link.active:focus,
.repairer-marketplace-page .nav-tabs-2 .nav-link.active:hover {
  color: #79B1BB !important;
}

.repairer-marketplace-page .nav-tabs.nav-tabs-2 {
  padding-left: 0;
}

.repairer-marketplace-page .tab-content-table {
  border-top: none;
}

.repairer-marketplace-page .nav-tabs-2 .nav-link.active {
  color: #79B1BB;
}

.repairer-marketplace-page .active-report {
  color: #79B1BB;
}

.repairer-marketplace-page .active-menu-item {
  color: #79B1BB !important;
  opacity: 1 !important;
}

.repairer-marketplace-page .btn-orders-block {
  margin: 15px 0 15px 15px;
  cursor: pointer;
}

.repairer-marketplace-page .btn-orders-block:hover {
  color: #79B1BB;
}

.repairer-marketplace-page .title-orders-block {
  font-weight: bold;
  margin: 15px 0 15px 0;
}
</style>

<style>
.repairer-marketplace-page .table-wrapper .qty-block {
  max-width: 60px;
}
</style>
