<template>
  <div>
    <h4 v-if="companyInfo && companyInfo.isCCNSW">Automotive
      <span> \ </span>
      <span >Consumables</span>
      <span> \ </span>
      <span  style="cursor:pointer;" @click="reOpenStore">M&S Automotive Paint Suppliers</span></h4>
    <template v-if="companyInfo  && companyInfo.isCCNSW">
      <iframe
          src="/marketplace-96072300.html"
          frameborder="0"
          class="iframe-wrapper"
          id="marketplace-96072300"
      >
      </iframe>
    </template>
  </div>
</template>


<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";

export default defineComponent({
  name: "store-96072300",
  props: {
  },
  computed: {
    ...mapGetters({
      companyInfo: 'currentCompany/getDetails',
    })
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    reOpenStore(){
      let storeId='marketplace-96072300'

        let iframe = document?.getElementById(storeId)
        _.forEach(iframe?.contentWindow?.document?.querySelectorAll('script'), el => {
          if (_.includes(el?.innerHTML,'Ecwid.OnAPILoaded.add(function() {Ecwid.openPage(\'category\',')) {
            el.parentNode.removeChild(el);
          }
        })

        const script = document.createElement('script');
        script.setAttribute('type', 'module');
        script.text = `Ecwid.OnAPILoaded.add(function() {Ecwid.openPage('category', { id:0 });})`
        iframe.contentWindow.document.body.appendChild(script);
    }
  },
})
</script>
<style scoped>
.hidden-el {
  visibility: hidden !important;
  height: 1px !important;
  width: 1px !important;
  position: absolute;
  top: -2000px;
}
.visible-el {
  visibility: visible !important;
}

.iframe-wrapper {
  width: 100%;
  /*height: calc(100vh - 700px);*/
  height: calc(100vh - 150px);
}

.company-logo{
  background-image: url(../../../assets/logos/MS_Automotive_Paint_Supplies_logo.png);
  height: 150px;
  width: 400px;
  background-size: cover;
}
.company-info{
  margin-top: 20px;
  background-color: #f0f0f0;
  border: 1px solid rgba(27, 30, 56, 0.2);
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 13px;
}
input.form-control.search-input {
  padding-left: 10px !important;
  padding-right: 35px;
  height: 32px;
  width: 250px;
  background: url('../../../assets/img/search-icon.svg') no-repeat center right;
}
.company-production{
  margin-top: 20px;
}
.company-production-body{
  flex-wrap: wrap;
}
.company-production-body div{
  width: 25%;
}
.company-production-body div iframe{
  margin: 10px 20px;
  height: 250px;
  border: 2px solid #d0cece;
  border-radius: 3px;
  background-color: #eeeeee;
}
@media screen and (max-width: 1730px) {
  .company-production-body div{
    width: 33.333%;
  }
}
@media screen and (max-width: 1400px) {
  .company-production-body div{
    width: 50%;
  }
}
@media screen and (max-width: 1100px) {
  .company-production-body div{
    width: 100%;
    text-align: center;
  }
}
</style>
